@import '../../partials/colors';
@import '../../partials/mixins';

.steps {
  max-width: 1400px;
  width: 100%;
  margin-bottom: 4rem;
  padding: 0 2rem;
  @include tablet {
    padding: 0 4.444rem;
  };
  @include mobile {
    padding: 0;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.611rem;
  };
  &__textbox {
    width: 50%;
    text-align: center;
    padding: 30px;
    @include tablet {
      padding: 1.111rem;
    };
    @include mobile {
      // sth
    }
  }
  &__subtitle {
    color: $brown;
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 2.188rem;
    @include tablet {
      font-size: 1.389rem;
      margin-bottom: 1.222rem;
    };
    @include mobile {
      font-size: 0.778rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  }
  &__text {
    font-family: 'bicyclette';
    font-size: 1.5rem;
    font-weight: 400;
    @include tablet {
      font-size: 0.889rem;
    };
    @include mobile {
      font-size: 0.667rem;
      margin-top: 1.222rem;
      text-align: center;
    }
  }
  &__image {
    width: 50%;
    padding: 0 1.875rem;
    @include mobile {
      width: 100%;
      padding: 0;
    };
  }
  &__row {
    width: 100%;
    display: flex;
    justify-content: space-around;
    @include mobile {
      display: none;
    }
  }
  &__column {
    display: none;
    @include mobile {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

div.steps:nth-of-type(2) .steps__row:nth-of-type(1) {
  flex-direction: row-reverse;
}
