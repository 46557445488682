@import '../../partials/colors';
@import '../../partials/mixins';

.testimonialvideo {
	padding: 3rem 3rem 1rem;
  width: 100%;
	@include mobile {
		padding: 0%;
	}
	&__container {
		display: flex;
		justify-content: center;
		align-items: center;
		@include mobile {
			display: none;
		}
	}
	&__content {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		max-width: 1400px;
		&--inverse {
			flex-direction: row-reverse;
		}
	}
	&__mobile {
		@include medium {
			display: none;
		}
		@include tablet {
			display: none;
		}
		@include mobile {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: 100%;
		}
	}
	&__contentbox {
		padding: 2rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 50%;
		@include tablet {
			padding: 1rem 2.85rem;
			width: 55%;
		}
		@include mobile {
			padding: 1.944rem 1.667rem;
			width: 100%;
		}
	}
	&__title {
		text-align: center;
		color: $brown;
		@include sectionTitle;
		@include tablet {
			font-size: 1rem;
		}
		@include mobile {
			font-size: 0.889rem;
			margin-bottom: 1.167rem;
		}
	}
	&__text {
		margin: 2.2rem 0;
		text-align: center;
		@include regularText;
		@include tablet {
			font-size: 0.65rem;
			line-height: 1.25rem;
			margin: 1.5rem 0 1rem;
		}
		@include mobile {
			line-height: 1.12rem;
			margin: 1.389rem 0 1.111rem;
		}
	}
	&__buttonbox {
		display: flex;
	}
	&__link {

	}
	&__button {
		background-color: $navy;
		border-radius: 38px;
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 0.8rem;
		width: 160px;
		height: 40px;
		margin: 0 1.25rem;
		@include tablet {
			font-size: 0.5rem;
			width: 115px;
			height: 30px;
			margin: 0 0.938rem;
		}
		@include mobile {
			width: 118px;
			height: 25px;
			margin: 0 0.556rem;
		}
	}
	&__video {
		width: 50%;
		height: 375px;
		
		@include tablet {
			width: 45%;
		}
		@include mobile {
			width: 100%;
			height: 190px;
		}
	}
}