@import '../../partials/colors';
@import '../../partials/mixins';

.heroFeature {
	&__image {
		object-fit: cover;
		width: 100%;
	}
}

.featured {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 4.336rem 3rem 0;
	&__title {
		@include sectionTitle;
		color: $brown;
		margin-bottom: 4.688rem;
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		max-width: 900px;
		justify-content: space-between;
	}
}