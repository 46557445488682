@import '../../partials/colors';
@import '../../partials/mixins';

.requestform {
	background-color: $navy;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 4rem;
	margin-top: 3.5rem;
	@include tablet {
		margin-top: 2.778rem;
		padding: 3.056rem;
	};
	@include mobile {
		margin-top: 2.667rem;
		padding: 1.667rem 1.667rem 2.222rem;
		flex-direction: column;
		align-items: center;
	}
	&__infobox {
		width: 518.748px;
		@include tablet {
			width: 350px;
		};
		@include mobile {
			width: 250px;
			text-align: center;
			margin-bottom: 1.389rem;
		};
	}
	&__title {
		@include sectionTitle;
		color: $beige;
		margin-bottom: 3.056rem;
		@include tablet {
			font-size: 0.944rem;
			margin-bottom: 1.389rem;
		};
		@include mobile {
			font-size: 0.778rem;
			margin-bottom: 0.944rem;
		}
	}
	&__subtitle {
		color: $beige;
		font-size: 1.367rem;
		font-family: 'bicyclette';
		font-weight: 700;
		margin-bottom: 1rem;
		@include tablet {
			font-size: 0.889rem;
			margin-bottom: 0.778rem;
		};
		@include mobile {
			font-size: 0.667rem;
			margin-bottom: 0;
		}
	}
	&__text {
		color: $beige;
		font-size: 1.1rem;
		font-family: 'bicyclette';
		font-weight: 700;
		margin-bottom: 1rem;
		@include tablet {
			font-size: 0.889rem;
			margin-bottom: 0.778rem;
		};
		@include mobile {
			font-size: 0.667rem;
			margin-bottom: 0;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
		width: 500px;
		align-items: center;
		@include tablet {
			width: 380px;
		};
		@include mobile {
			width: 270px;
		}
	}
	&__textinput {
		width: 100%;
		height: 50px;
		margin-bottom: 1rem;
		padding: 0.1rem;

		font-size: 1rem;
		background-color: transparent;
		border: none;
		border-bottom: 3px solid white;
		color: white;
		@include tablet {
			height: 25px;
			border-bottom: 2px solid white;
			font-size: 0.778rem;
		};
		@include mobile {
			height: 15px;
		};
	};
	&__textinput::placeholder {
		color: white;
		opacity: 1;
		font-size: 0.978rem;
		font-weight: 700;
		@include tablet {
			font-size: 0.556rem;
		};
	}
	&__textarea {
		width: 100%;
		height: 100px;
		padding: 0.1rem;
		margin-bottom: 1.222rem;

		font-size: 1rem;
		background-color: transparent;
		border: none;
		border-bottom: 3px solid white;
		color: white;
		@include tablet {
			border-bottom: 2px solid white;
			font-size: 0.778rem;
		};
	}
	&__textarea::placeholder {
		color: white;
		opacity: 1;
		font-size: 0.978rem;
		font-weight: 700;
		@include tablet {
			font-size: 0.556rem;
		};
	}
	&__button {
		background-color: $tan;
		width: 160px;
		height: 40px;
		border-radius: 38px;
		font-family: 'bicyclette';
		font-size: 1rem;
		font-weight: 700;
		color: $beige;
		@include tablet {
			width: 110px;
			height: 25px;
			font-size: 0.556rem;
		};
		@include mobile {
			width: 100px;
			height: 22px;
			font-size: 0.5rem;
		}
	}
}