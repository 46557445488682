@import '../../partials/colors';
@import '../../partials/mixins';

.heroproperty {
  position: relative;
  text-align: center;
  &__img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    @include tablet {
      height: 336px;
    };
  }
  &__container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 5%;
  }
  &__text {
    font-family: 'bicyclette';
    font-size: 1.111rem;
    color: $beige;
    margin: 0 2rem;
    @include tablet {
      font-size: 0.778rem;
    };
    @include mobile {
      margin: 0 0.5rem;
    }
  }
}