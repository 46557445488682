@import '../../partials/colors';
@import '../../partials/mixins';

.heroabout {
	&__image {
		width: 100%;
		object-fit: cover;
	}
}

.meettheteam {
	display: flex;
	flex-direction: column;
	align-items: center;
	&__title {
		@include sectionTitle;
		color: $brown;
		@include tablet {
			font-size: 1.1rem;
		}
		@include mobile {
			font-size: 0.889rem;
		}
	}
}