@import '../../partials/colors';
@import '../../partials/mixins';

.contactform {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 3rem 0;
	@include tablet {
		padding: 2rem 0;
	}
	&__title {
		@include sectionTitle;
		color: $brown;
		margin-bottom: 2.5rem;
		@include tablet {
			font-size: 1.111rem;
			margin-bottom: 2rem;
		}
		@include mobile {
			font-size: 0.889rem;
			margin-bottom: 1.667rem;
		}
	}
	&__form {
		display: flex;
		flex-direction: column;
		width: 100%;
		max-width: 600px;
		@include tablet {
			max-width: 330px;
		}
		@include mobile {
			max-width: 250px;
		}
	}
	&__label {
		margin-bottom: 0.5rem;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 1.1rem;
		color: $navy;
		@include tablet {
			font-size: 0.556rem;
			margin-bottom: 0.438rem;
		}
		@include mobile {
			font-size: 0.5rem;
			margin-bottom: 0.389rem;
		}
	}
	&__textinput {
		width: 100%;
		height: 37.5px;
		border: none;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		margin-bottom: 1rem;
		padding: 0.5rem;
		font-family: 'bicyclette';
		font-weight: 400;
		@include tablet {
			height: 25px;
		}
	}
	&__select {
		width: 100%;
		height: 37.5px;
		border: none;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		margin-bottom: 1rem;
		padding: 0.5rem;
		font-family: 'bicyclette';
		font-weight: 400;
	}
	&__option {

	}
	&__textarea {
		width: 100%;
		height: 120px;
		border: none;
		box-shadow: 0 3px 6px rgba(0,0,0,0.16);
		padding: 0.5rem;
		font-family: 'bicyclette';
		font-weight: 400;
	}
	&__button {
		background-color: $navy;
		border-radius: 38px;
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 0.9rem;
		width: 170px;
		height: 40px;
		align-self: center;
		margin-top: 3rem;
		@include tablet {
			margin-top: 1.667rem;
			font-size: 0.5rem;
			width: 115px;
			height: 30px;
		}
		@include mobile {
			margin-top: 1.111rem;
			width: 118px;
			height: 24px;
		}
	}
}