@import '../../partials/colors';
@import '../../partials/mixins';

.propertylisting {
	&__videobox {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	&__video {
		width: 100vw;
		height: 100vh;
	}
	&__title {
		@include sectionTitle;
		color: $brown;
		margin-bottom: 2rem;
		font-weight: 900;
	}
	&__gallery {
		position: relative;
  	text-align: center;
  	margin-bottom: 4rem;
	}
	&__image {
		width: 100%;
    height: 100vh;
    object-fit: cover;
    margin-top: 3rem;
	}
}