@import '../../partials/colors';
@import '../../partials/mixins';

.herolocal {
	width: 100%;
	&__image {
		width: 100%;
		@include tablet {
			height: 320px;
		};
		@include mobile {
			height: 200px;
		}
	}
}

.cardbox {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 2rem;
	@include tablet {
		padding: 0 4.167rem;
	};
	@include mobile {
		padding: 0 2.222rem;
	}
	&__list {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		width: 100%;
		max-width: 1200px;
	}
}