@import '../../partials/colors';
@import '../../partials/mixins';

.heroflorida {
  &__image {
    width: 100%;
  }
}

.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 9.25rem;
  .steps__title {
    font-family: 'bicyclette';
    font-weight: 900;
    color: $brown;
    margin-bottom: 4.68rem;
    @include tablet {
      font-size: 1.667rem;
    };
    @include mobile {
      font-size: 0.889rem;
    };
  }
  .steps__button {
    margin-top: 4.68rem;
    background-color: $navy;
		border-radius: 38px;
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 0.75rem;
		width: 200px;
		height: 40px;
  }
}