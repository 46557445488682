@import '../../partials/colors';
@import '../../partials/mixins';

.herohome {
	width: 100%;
	position: relative;
	&__image {
		width: 100%;
		object-fit: cover;
	}
	&__contentbox {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto auto;
		width: 600px;
		height: 600px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	&__title {
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 900;
		font-size: 2.344rem;
	}
	&__logo{
		width: 100%;
	}
	&__subtitle {
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 1.562rem;
		margin: 1.875rem 0 3.398rem;
	}
	&__buttonbox {
		display: flex;
	}
	&__button {
		margin: 0 2.9rem;
		background-color: $navy;
		border-radius: 38px;
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 1.1rem;
		width: 220px;
		height: 52px;
	}
	@include tablet {
		&__title {
			font-size: 1.875rem;
		}
		&__subtitle {
			font-size: 1rem;
			margin: 1rem 0 1.25rem;
		}
		&__button {
			font-size: 0.5rem;
			width: 95px;
			height: 25px;
		}
	}
	@include mobile {
		&__contentbox {
			width: 300px;
			height: 400px;
		}
		&__title {
			font-size: 1.3rem;
		}
		&__subtitle {
			margin: 1.5rem 0 3.5rem;
			font-size: 1rem;
		}
		&__image {
			height: 420px;
		}
		&__button {
			margin: 0 1rem;
			width: 110px;
			font-size: 0.6rem;
		}
	}
}

.abouthome {
	padding: 3.828rem 0 0.75rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	&__title {
		color: $brown;
		@include sectionTitle;
	}
	@include tablet {
		&__title {
			font-size: 1.3rem;
		}
	}
	@include mobile {
		padding: 3rem 0 0.75rem;
		&__title {
			font-size: 1.1rem;
		}
	}
}