@import '../../partials/colors';
@import '../../partials/mixins';

.generaltext {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 5rem 0 4.5rem;
	@include tablet {
		padding: 2.813rem 0 1rem;
	}
	&--withbutton {
		padding: 5rem 0 3rem;
		@include tablet {
			padding: 2.813rem 0 1rem;
		};
		@include mobile {
			padding: 1.944rem 0 2rem;
		};
	}
	&--withbutton &__title {
		@include tablet {
			font-size: 1.1rem;
			margin-bottom: 1.833rem;
		};
		@include mobile {
			font-size: 0.889rem;
			margin-bottom: 1.444rem;
		}
	}
	&--notop {
		padding: 0 0 4.5rem 0;
		@include tablet {
			padding: 0 0 3.056rem 0;
		};
		@include mobile {
			padding: 0 0 2.778rem 0;
		}
	}
	&--notop &__title {
		@include tablet {
			font-size: 1.667rem;
			margin-bottom: 1.389rem;
		};
		@include mobile {
			font-size: 0.889rem;
			margin-bottom: 1.111rem;
		}
	}
	&__content {
		width: 100%;
		max-width: 800px;
		display: flex;
		flex-direction: column;
		align-items: center;
		@include tablet {
			padding: 0 1.333rem;
			text-align: center;
		};
		@include mobile {
			padding: 0 1.944rem;
		};
	}
	&__title {
		@include sectionTitle;
		margin-bottom: 2rem;
		color: $brown;
		@include tablet {
			font-size: 1.1rem;
			margin-bottom: 1.833rem;
		}
		@include mobile {
			font-size: 0.889rem;
			margin-bottom: 1.444rem;
		}
	}
	&__text {
		font-family: 'bicyclette';
		font-weight: 400;
		font-size: 1.4rem;
		color: $black;
		text-align: center;
		width: 100%;
		@include tablet {
			font-size: 0.778rem;
		}
	}
	&__buttonbox {
		display: flex;
		margin-top: 2rem;
		@include tablet {
			margin-top: 1.833rem;
		};
		@include mobile {
			margin-top: 0.833rem;
		}
	}
	&__link {

	}
	&__button {
		background-color: $navy;
		border-radius: 38px;
		color: $beige;
		font-family: 'bicyclette';
		font-weight: 700;
		font-size: 0.9rem;
		width: 170px;
		height: 40px;
		margin: 0 2rem;
		@include tablet {
			width: 113px;
			height: 34px;
			font-size: 0.5rem;
		};
		@include mobile {
			width: 110px;
			height: 24px;
			margin: 0 0.566rem;
			font-size: 0.444rem;
		}
	}
}